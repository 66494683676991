import React from "react";
import { FormGroup, FormGroupPropTypes } from "@ui5/webcomponents-react";

interface IFormGroupProps extends FormGroupPropTypes {
  name: string;
  key: string;
  title?: string;
}

function FtFormGroup(props: IFormGroupProps) {
  const { title, ...rest } = props;
  return (
    <FormGroup titleText={title} {...rest}>
      {props.children}
    </FormGroup>
  );
}

FtFormGroup.displayName = "FormGroup in ft-react";
export default FtFormGroup;
