var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var slice_exports = {};
__export(slice_exports, {
  default: () => slice_default,
  rendererActions: () => rendererActions
});
module.exports = __toCommonJS(slice_exports);
var import_lib_core = require("@bsgp/lib-core");
var import_toolkit = require("@reduxjs/toolkit");
const initialState = {
  forms: {},
  tables: {},
  dialogs: {},
  hints: {},
  codeeditors: {},
  nodeeditors: {},
  selectedFormId: "",
  bak: {}
};
const rendererSlice = (0, import_toolkit.createSlice)({
  name: "rendererSlice",
  initialState,
  reducers: {
    _updateDialogData: (state, action) => {
      const { dialogKey, key, value } = action.payload;
      if (!state.dialogs[dialogKey]) {
        state.dialogs[dialogKey] = {};
      }
      state.dialogs[dialogKey][key] = value;
      if (["isOpen", "initialized"].includes(key)) {
        if (value === true) {
          const newState = (0, import_lib_core.clone)(state);
          delete newState.bak[dialogKey];
          state.bak[dialogKey] = newState;
        }
      }
    },
    _updateFormData: (state, action) => {
      const { formKey, componentKey, value } = action.payload;
      if (!state.forms[formKey]) {
        state.forms[formKey] = {};
      }
      state.forms[formKey][componentKey] = value;
    },
    _overwriteTableData: (state, action) => {
      const { key, value } = action.payload;
      state.tables[key] = (0, import_lib_core.clone)(value);
    },
    _updateTableData: (state, action) => {
      const { key, value, index, colName, rowData } = action.payload;
      if (rowData === void 0) {
        state.tables[key][index][colName] = value;
      } else {
        state.tables[key][index] = (0, import_lib_core.clone)(rowData);
      }
    },
    _updateComponentData: (state, action) => {
      const { componentCtg, componentKey, value } = action.payload;
      state[componentCtg][componentKey] = value;
    },
    _selectFormId: (state, action) => {
      const { formKey } = action.payload;
      state.selectedFormId = formKey;
    },
    _updateDataHints: (state, action) => {
      const { hintKey, data } = action.payload;
      state.hints[`${hintKey}`] = data;
    },
    _updateValueFunction: (state, action) => {
      const { type, formKey, componentKey, value } = action.payload;
      state[`${type}`][`${formKey}`][`${componentKey}`] = value;
    },
    _restoreState: (state, action) => {
      const dialogKey = action.payload;
      return state.bak[dialogKey];
    },
    _overwriteState: (state, action) => {
      action.payload(state);
    },
    _initialSpread: (state, action) => {
      const { meta } = action.payload;
      if (meta.forms) {
        meta.forms.forEach((form) => {
          if (state.forms[form.key] === void 0) {
            state.forms[form.key] = {};
          }
        });
      }
      if (meta.tables) {
        meta.tables.forEach((table) => {
          if (state.tables[table.key] === void 0) {
            state.tables[table.key] = [];
          }
        });
      }
      if (meta.dialogs) {
        meta.dialogs.forEach((dlg) => {
          if (state.dialogs[dlg.key] === void 0) {
            state.dialogs[dlg.key] = {};
          }
          if (dlg.forms) {
            dlg.forms.forEach((form) => {
              if (state.forms[form.key] === void 0) {
                state.forms[form.key] = {};
              }
            });
          }
          if (dlg.tables) {
            dlg.tables.forEach((table) => {
              if (state.tables[table.key] === void 0) {
                state.tables[table.key] = [];
              }
            });
          }
        });
      }
    },
    _initAll: () => {
      return rendererSlice.getInitialState();
    }
  }
});
const rendererActions = rendererSlice.actions;
var slice_default = rendererSlice;
