import React, { useRef, useEffect } from "react";
import { Input } from "@ui5/webcomponents-react";
import { isFalsy, defined } from "@bsgp/lib-core";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import "@ui5/webcomponents/dist/features/InputSuggestions.js";
import MultiInput from "./MultiInput";
import * as all from "@bsgp/form-and-table";
import { IInputProps } from "@/types/component";
import { useValueHelpV2 } from "@/hooks/dialog";
import clsx from "clsx";

const { convertNumber, escapeValue } = all;

const FtInput = (props: IInputProps) => {
  const {
    style,
    properties = {},
    multiple,
    isAmount,
    isQuantity,
    unit,
    value
  } = props;

  const {
    change: onChange,
    focus,
    valueHelpV2,
    valueHelpOnly,
    disabled,
    ...rest
  } = properties;
  const { icon, valueHelpProps, valueHelpClass } = useValueHelpV2(props);

  const isNumber = isAmount || isQuantity;
  const inputEl = useRef(null);

  const disabledStyleClass = "disabled";

  useEffect(() => {
    isFalsy(focus) || inputEl.current.focus();
  }, [focus]);

  if (multiple) {
    return <MultiInput {...props} />;
  }

  if (properties.mask) {
    // return <MaskInput />;
  }

  return (
    <Input
      icon={icon}
      {...props}
      data-type="Input"
      ref={inputEl}
      className={clsx(disabled === true && disabledStyleClass, valueHelpClass)}
      style={{
        textAlign: isNumber ? "right" : "start",
        width: "100%",
        ...style
      }}
      title={defined(props.tooltip, value, "")}
      onInput={event => {
        let newValue = escapeValue(props.value);
        if (isNumber) {
          newValue = convertNumber(newValue, unit, {
            isAmount,
            isQuantity
          });
        }
        return defined(props.onInput, () => {})(event, {
          value: newValue
        });
      }}
      // onFocus={event => {
      //   event.target.getFocusDomRef().select();
      // }}
      value={value}
      onKeyDown={event => {
        if (event.key === "Enter") {
          props.onSubmit && props.onSubmit(event);
        }
      }}
      valueStateMessage={<>{properties.valueStateMessage || ""}</>}
      {...valueHelpProps}
      {...rest}
    >
      {props.children}
    </Input>
  );
};

FtInput.displayName = "FtInput";

export default FtInput;
