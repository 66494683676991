import React, { ReactNode } from "react";
import { tryit } from "@bsgp/lib-core";
import { FlexBox } from "@/components";
import { isArray } from "@bsgp/lib-core";
import ComponentBuilder from "@/builders/common/ComponentBuilder";
import { IComponentBuilderProps } from "@/types/common";
import { IElement } from "@/types/form";
import { getComponentName } from "@/lib/utils";

interface IFormComponentIteratorProps {
  hasMultiComponents?: boolean;
  elName: string;
  element: IElement;
}

function FormComponentIterator({
  hasMultiComponents,
  element,
  elName
}: IFormComponentIteratorProps): ReactNode {
  if (hasMultiComponents) {
    const fieldComponents = element.field.component as IComponentBuilderProps[];
    return fieldComponents.map((fieldComponent, index) => {
      const fieldName = getComponentName({ elName, fieldComponent });
      const isFullWidth = component =>
        tryit(() => component.properties.width) === "100%";

      const renderingComponentBuilder = (
        <ComponentBuilder
          name={fieldName}
          id={fieldName}
          key={fieldName}
          value={element.field.value[index]}
          conv={
            isArray(element.field.conv)
              ? element.field.conv[index]
              : element.field.conv
          }
          {...fieldComponent}
        />
      );

      return isFullWidth(fieldComponent) ? (
        <FlexBox name={`${fieldName}_layout`} key={`${fieldName}_layout`}>
          {renderingComponentBuilder}
        </FlexBox>
      ) : (
        renderingComponentBuilder
      );
    });
  } else {
    const { component, value, conv } = element.field;
    return (
      <ComponentBuilder
        id={element.name}
        key={element.name}
        value={value}
        conv={conv}
        {...component}
      />
    );
  }
}

export default FormComponentIterator;
