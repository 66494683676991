import applyPagination, { compareSearchInput } from "./use-pagination";
import { isArray } from "@bsgp/lib-core";

function getTargetColumns(tableData) {
  const searchData = tableData.toolbar.content.find(
    ({ component }) => component.type === "SearchField"
  );
  if (searchData.targetColumns) {
    return searchData.targetColumns;
  }

  return tableData.columns.reduce((acc, { name, component }) => {
    acc.push(name);
    if (isArray(component)) {
      component.forEach(({ name }) => acc.push(name));
    }
    return acc;
  }, []);
}

function applySearchOnTable(oEvent, tableData) {
  const inputValue = oEvent.getParameter("query");
  const oSearch = oEvent.getSource();
  const oTable = oSearch.getParent().getParent();

  const targetColumns = getTargetColumns(tableData);
  const searchFilter = new window.sap.ui.model.Filter({
    filters: targetColumns.map(
      name =>
        new window.sap.ui.model.Filter({
          path: name,
          test: compareSearchInput(inputValue)
        })
    ),
    and: false
  });

  if (!tableData.usePagination) {
    oTable.getBinding("items").filter(searchFilter);
    return;
  }

  let count = -1;
  const { itemsPerPage = 20 } = tableData.usePagination;
  const indexFilter = new window.sap.ui.model.Filter({
    path: "",
    test: () => {
      count += 1;
      return count >= 0 && count < itemsPerPage;
    }
  });
  oTable.getBinding("items").filter([searchFilter, indexFilter]);

  applyPagination(oTable, tableData).onSearch();
}

function getCustomDataByKey(comp, key) {
  return comp.getCustomData().find(item => item.getKey() === key);
}

function handleSearchInput(oSearch, tableData) {
  const eList = oSearch.mEventRegistry.search || [];
  if (eList.length > 0) {
    const prevHandler = eList[0].fFunction;
    oSearch.detachSearch(prevHandler);
  }
  oSearch.attachSearch(undefined, oEvent => {
    const oSearchAfterInput = oEvent.getSource();
    const searchInput = oEvent.getParameter("query");
    getCustomDataByKey(oSearchAfterInput, "search").setValue(searchInput);

    applySearchOnTable(oEvent, tableData);
  });
}

export default handleSearchInput;
