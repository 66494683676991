import React from "react";
import { Select, Option } from "@ui5/webcomponents-react";
import { ISelectProps, SelectOnChangeType } from "@/types/component";

function FtSelect(props: ISelectProps) {
  const {
    properties,
    rowData = {},
    items: { list },
    value
  } = props;
  const onChange = (properties.change ||
    properties.onChange) as SelectOnChangeType;

  return (
    <Select
      {...props}
      onChange={function(event) {
        return onChange && onChange(event, { rowData });
      }}
    >
      {list.map((opt, index) => {
        return (
          <Option
            key={`${opt.key}_${index}`}
            value={opt.key}
            selected={opt.key === value}
          >
            {opt.text}
          </Option>
        );
      })}
    </Select>
  );
}

export default FtSelect;
