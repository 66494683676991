import React from "react";
import { HBox, List, CustomListItem } from "@/components";
import { FlexBoxWrap } from "@ui5/webcomponents-react";
import { useTableContext } from "@/contexts/tableContext";

const ListBuilder = props => {
  const { tableProperties } = useTableContext();
  const { data, tableName, children } = props;

  return (
    <List {...tableProperties}>
      <CustomListItem name={`${tableName}_items`} {...data.items.properties}>
        <HBox name={`${tableName}_items_cont`} wrap={FlexBoxWrap.Wrap}>
          {children}
        </HBox>
      </CustomListItem>
    </List>
  );
};

export default ListBuilder;
