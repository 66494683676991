import React from "react";

const Image = props => {
  const { properties, value, alt, height, width } = props;
  const style: React.CSSProperties = {};
  if (height) {
    style.height = height;
  }
  if (width) {
    style.width = width;
  }

  return <img src={value} alt={alt} style={style} {...properties} />;
};

export default Image;
