import React from "react";
import { Link } from "@ui5/webcomponents-react";
import { LinkDesign } from "@ui5/webcomponents-react";

function ExLink(props) {
  const { value, properties, rowData, ...rest } = props;
  const design = getLinkDesign(properties) || LinkDesign.Default;

  return (
    <Link {...properties} {...rest} design={design}>
      {value}
    </Link>
  );
}

function getLinkDesign(properties) {
  const keys = Object.keys(LinkDesign);
  const design = keys.find(key => properties[key.toLowerCase()] === true);
  return design;
}

export default ExLink;
