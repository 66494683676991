import React, { memo } from "react";
import {
  AnalyticalTable,
  AnalyticalTableColumnDefinition,
  AnalyticalTableScaleWidthMode,
  AnalyticalTableSelectionBehavior
} from "@ui5/webcomponents-react";
import { tryit } from "@bsgp/lib-core";
import { useTableContext } from "@/contexts/tableContext";

const AnalyticalTableBuilder = props => {
  const {
    dataDisplaying,
    tableProperties,
    setSelectedIndices
  } = useTableContext();
  const { header, footer } = props;
  const { columnProperties, mode, ...rest } = tableProperties;

  const columns = columnProperties.columns as AnalyticalTableColumnDefinition[];

  return (
    <div>
      <AnalyticalTable
        filterable={true}
        scaleWidthMode={AnalyticalTableScaleWidthMode.Grow}
        selectionBehavior={AnalyticalTableSelectionBehavior.RowSelector}
        minRows={1}
        header={header}
        {...rest}
        columns={columns}
        selectionMode={mode}
        data={dataDisplaying}
        alternateRowColor={props.alternateRowColors}
        // visibleRows={
        //   dataDisplaying.length > 0
        //     ? props.isTreeTable === true
        //       ? undefined
        //       : dataDisplaying.length
        //     : minRows
        // }
        // hasToolbar={props.hasToolbar}
        onRowSelect={event => {
          const isSelected = tryit(() => event.detail.row.isSelected);
          const isAllSelected = event.detail.allRowsSelected;
          const selectedRow = Object.keys(event.detail.selectedRowIds);
          setSelectedIndices(selectedRow);
          console.log("you can add some row select logic here");
          console.log("isSelected : ", isSelected);
          console.log("isAllSelected : ", isAllSelected);
          console.log("selectedRow : ", selectedRow);
        }}
      />
      {footer}
    </div>
  );
};

export default memo(AnalyticalTableBuilder);
