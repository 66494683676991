import React from "react";
import { FormItem, FormItemPropTypes } from "@ui5/webcomponents-react";

interface IFormItemProps extends FormItemPropTypes {
  name: string;
  key?: string;
  visible: boolean;
}

function FtFormItem(props: IFormItemProps) {
  return <FormItem {...props} />;
}

FtFormItem.displayName = "FormItem in ft-react";
export default FtFormItem;
