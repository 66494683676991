import React, { createRef } from "react";
import { ICommonProps } from "@/types/common";

type PropsType = {
  props: ICommonProps;
  fn: object;
};

export const formRef = createRef<HTMLFormElement>();
export const propsRef: React.MutableRefObject<PropsType> = createRef<
  PropsType
>();

export const setPropsRef = ({ props, fn }) => {
  propsRef.current = {
    props,
    fn
  };
};
