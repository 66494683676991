import React from "react";
import { DateRangePicker } from "@ui5/webcomponents-react";
import { isDate } from "@bsgp/lib-core";

const FtDateRangePicker = props => {
  const { value, properties, displayFormat, format, style } = props;

  const formatPattern = displayFormat || format || "yyyy-MM-dd";

  return (
    <DateRangePicker
      {...properties}
      formatPattern={formatPattern}
      value={refineValue(value)}
      style={style}
    />
  );
};

function refineValue(value) {
  if (Array.isArray(value)) {
    if (value.filter(Boolean).length === 0) return "";
    return value
      .map(val => {
        if (isDate(val)) {
          return isoToDateOnly(val);
        }
        return val;
      })
      .join("-");
  }
  return value;
}

function isoToDateOnly(date) {
  // Extract the year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export default FtDateRangePicker;
