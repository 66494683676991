import React from "react";
import {
  FlexBox,
  FlexBoxPropTypes,
  FlexBoxAlignItems,
  FlexBoxJustifyContent,
  FlexBoxDirection
} from "@ui5/webcomponents-react";

interface IBoxProps extends FlexBoxPropTypes {
  key?: string;
  name?: string;
  hAlign?: FlexBoxJustifyContent | keyof typeof FlexBoxJustifyContent;
  vAlign?: FlexBoxAlignItems | keyof typeof FlexBoxAlignItems;
}

function HBox(props: IBoxProps) {
  const { style, hAlign, vAlign, ...rest } = props;
  const additionalProps = {
    justifyContent: hAlign,
    alignItems: vAlign,
    ...rest
  };
  return (
    <FlexBox
      direction={FlexBoxDirection.Row}
      {...additionalProps}
      style={{ ...style }}
      slot={props.slot}
    >
      {props.children}
    </FlexBox>
  );
}

export default HBox;
