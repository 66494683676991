import React from "react";
import { tryit } from "@bsgp/lib-core";
import * as all from "@bsgp/form-and-table";
import { IConstructorProps } from "@/types/common";
import { refineEvent } from "@/lib/refineEvent";
import { propsRef } from "@/ref";
import BaseConstructor from "../common/BaseConstructor";
import TableIterator from "./TableIterator";

const { constructTableData } = all;

const TableConstructor = (props: IConstructorProps) => (
  <BaseConstructor
    {...props}
    dataKey="table"
    dataProcessor={data => {
      return constructTableData(data.table, propsRef.current.fn, "", {
        isReact: true,
        refineFunc: refineEvent
      });
    }}
    additionalCheck={() => !!propsRef.current.fn}
    additionalProps={{
      wrapSingleTable: tryit(() => props.data.wrapSingleTable)
    }}
  >
    {renderProps => <TableIterator {...renderProps} />}
  </BaseConstructor>
);
export default TableConstructor;
