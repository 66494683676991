import React, { ReactNode } from "react";
import { IComponentBuilderProps } from "@/types/common";
import { IComponentCommonProps, IRadioProps } from "@/types/component";
import { useRefineProperties } from "@/hooks/refineProps";
import * as all from "@bsgp/form-and-table";
import {
  DateRangePicker,
  DatePicker,
  DateTimePicker,
  Card,
  TimePicker,
  RadioButton,
  ToolbarSeparator
} from "@/components";
import * as Components from "@/components";
import { getStyleCodeFromProperties } from "@/lib/utils";

const { getNewValue, removeUndefinedKeys } = all;

const ComponentBuilder = (props: IComponentBuilderProps): ReactNode => {
  const {
    type = "Text",
    hiddenData,
    refineEventHookName,
    ...restProps
  } = props;
  const properties = useRefineProperties(props);

  const style = getStyleCodeFromProperties({ properties, props });
  const newValue = getNewValue(props.value, props.conv);
  const newProps: IComponentCommonProps = removeUndefinedKeys({
    ...restProps,
    ...((type === "MenuButton" || type === "MenuItem") && {
      refineEventHookName
    }),
    properties,
    type,
    value: newValue,
    hiddenData,
    label: props.label || "",
    styleClasses: props.styleClasses || [],
    style
  });

  if (type === "GenericTile") {
    return <Card {...newProps} />;
  } else if (type === "HTML") {
    return <>{newProps.children}</>;
  } else if (type === "DateTime") {
    return <DateTimePicker {...newProps} />;
  } else if (type === "Date") {
    return <DatePicker {...newProps} />;
  } else if (type === "Time") {
    return <TimePicker {...newProps} />;
  } else if (type === "DateRange") {
    return <DateRangePicker {...newProps} />;
  } else if (type === "Radio") {
    return <RadioButton {...(newProps as IRadioProps)} />;
  } else if (type === "FormattedText") {
    return <div {...newProps} />;
  } else if (type === "Separator") {
    return <ToolbarSeparator {...newProps} />;
  } else {
    const SingleComponent = Components[type];
    if (SingleComponent) {
      return <SingleComponent {...newProps} />;
    } else {
      return <div>Component not found</div>;
    }
  }
};

export default ComponentBuilder;
