import React, { useCallback, useRef } from "react";
import { createPortal } from "react-dom";
import Button from "@/components/inputs/Button";
import { SortItem, ViewSettingsDialog } from "@ui5/webcomponents-react";
import "@ui5/webcomponents-fiori/dist/SortItem";
import { createSorter } from "@bsgp/lib-core";
import { useTableContext } from "@/contexts/tableContext";

const ViewSettings = props => {
  const { tableInfo, properties } = props;
  const { tableProperties } = useTableContext();
  const dialogRef = useRef(null);

  const handleOpen = useCallback(() => {
    dialogRef.current.show();
  }, [dialogRef]);

  return (
    <>
      <Button
        properties={{
          onClick: handleOpen,
          ...properties
        }}
      />
      {createPortal(
        <ViewSettingsDialog
          ref={dialogRef}
          onConfirm={event => {
            const selectedText = event.detail.sortBy;
            const selectedName = tableInfo.columns.find(
              col => col.text === selectedText
            ).name;
            const sortDescending = event.detail.sortDescending;

            tableProperties.onSort({
              sorter: createSorter(
                [selectedName],
                sortDescending ? "desc" : "asc"
              )
            });
          }}
          sortItems={tableInfo.columns.map(col => {
            return (
              <SortItem text={col.text} key={col.name} data-key={col.name} />
            );
          })}
        />,
        document.body
      )}
    </>
  );
};

export default ViewSettings;
