import React from "react";
import { ToolbarButton } from "@ui5/webcomponents-react";
import { IButtonProps } from "@/types/component";

const FtToolbarButton = (
  props: IButtonProps & { onClick: (e: any) => void }
) => {
  const { properties = {}, onClick, ...rest } = props;
  return (
    <ToolbarButton
      design="Default"
      {...properties}
      {...rest}
      onClick={function(event) {
        return onClick && onClick(event);
      }}
      text={props.value}
    />
  );
};

export default FtToolbarButton;
