import React, { useCallback, useEffect } from "react";
import { MenuItem } from "@ui5/webcomponents-react";
import { IMenuItemProps } from "@/types/component";
import ComponentBuilder from "@/builders/common/ComponentBuilder";

const FtMenuItem = (props: IMenuItemProps) => {
  const {
    value,
    properties = {},
    handleSelectedIdx,
    event,
    setEvent,
    items = {},
    getIndex,
    selectedIdx,
    refineEventHookName,
    ...rest
  } = props;

  const indexLabel = props["data-idx"];

  const handleClick = useCallback(
    event => {
      const onClick = properties.onClick || props.onClick;
      onClick(event);
      handleSelectedIdx(null);
      setEvent(null);
    },
    [properties, handleSelectedIdx, setEvent]
  );

  useEffect(() => {
    if (selectedIdx === indexLabel && !!event) {
      handleClick(event);
    }
  }, [selectedIdx, event, handleClick]);

  return (
    <MenuItem text={value} {...rest} {...properties}>
      {Object.entries(items).map(([key, btn], index) => {
        const curIndex = getIndex(index, indexLabel);
        return (
          <ComponentBuilder
            key={key}
            type="MenuItem"
            data-idx={curIndex}
            getIndex={getIndex}
            selectedIdx={selectedIdx}
            handleSelectedIdx={handleSelectedIdx}
            event={event}
            setEvent={setEvent}
            refineEventHookName={refineEventHookName}
            {...btn}
          />
        );
      })}
    </MenuItem>
  );
};

export default FtMenuItem;
