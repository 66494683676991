import { propsRef } from "@/ref";
import { refineEvent } from "@/lib/refineEvent";
import {
  IComponentBuilderProperties,
  IComponentBuilderProps
} from "@/types/common";
import { useTableContext } from "@/contexts/tableContext";
import { IRefineEventForTableProps } from "@/types/event";
import { getValueFromDataBindingFormat } from "@/lib/utils";
import { INodeEditorBuilderProps } from "@/types/nodeEditor";

export const useRefineEvent = (properties: IComponentBuilderProperties) => {
  const { fn } = propsRef.current;

  return refineEvent(properties, fn);
};

export const useRefineEventForTableToolbar = (
  properties: IRefineEventForTableProps
) => {
  const { fn } = propsRef.current;
  const { tableProperties, filteredData } = useTableContext();
  const {
    columnProperties: { columns }
  } = tableProperties;

  return refineEvent(
    properties,
    fn,
    {
      columns,
      tableData: filteredData
    },
    { shouldStoreInOEvent: true, shouldSkipLegacyFields: true }
  );
};

export const useRefineEventForTableCell = (
  properties: IComponentBuilderProperties,
  props: IComponentBuilderProps
) => {
  const { fn } = propsRef.current;
  const options = {
    rowData: props.rowData
  };

  return refineEvent(properties, fn, options);
};

export const useRefineEventForTreeTable = properties => {
  const { fn } = propsRef.current;
  const { tableData, selectedIndices } = useTableContext();

  return refineEvent(
    properties,
    fn,
    {
      dataList: tableData,
      indices: selectedIndices,
      usingPagination: false,
      items: tableData
    },
    { shouldSkipLegacyFields: true, shouldStoreInOEvent: true }
  );
};

export const useRefineEventForCodeEditor = (
  properties,
  doNotRefine = false
) => {
  if (doNotRefine) return properties;
  const { fn } = propsRef.current;

  return refineEvent(
    properties,
    fn,
    {},
    {
      shouldSkipLegacyFields: true
    }
  );
};

export const useRefineEventForNodeEditor = (properties, props) => {
  const { fn } = propsRef.current;
  const options = {
    nextId: props.nextId
  };

  return refineEvent(properties, fn, options, {
    shouldSkipLegacyFields: true
  }) as INodeEditorBuilderProps;
};

export const EVENT_REFINER_MAP = {
  useRefineEvent: useRefineEvent,
  useRefineEventForTableCell: useRefineEventForTableCell,
  useRefineEventForTableToolbar: useRefineEventForTableToolbar,
  useRefineEventForTreeTable: useRefineEventForTreeTable,
  useRefineEventForCodeEditor: useRefineEventForCodeEditor,
  useRefineEventForNodeEditor: useRefineEventForNodeEditor
};

export const useRefineProperties = props => {
  const { refineEventHookName = "useRefineEvent" } = props;

  const refineEvent = EVENT_REFINER_MAP[refineEventHookName];
  const properties =
    refineEventHookName === "none"
      ? props.properties
      : refineEvent(props.properties, props);

  const convertedProperties = convertConditionalProperties({
    props,
    properties
  });
  return convertedProperties;
};

const convertConditionalProperties = ({ props, properties }) => {
  const targetProperties = Object.keys(ConditionalPropertiesMigrateMap);
  const computedProperties = targetProperties.reduce((acc, propKey) => {
    const conditionValue = getConditionValue({ props, propKey });
    if (conditionValue === undefined) {
      return acc;
    }
    acc[propKey] = conditionValue;
    return acc;
  }, {});
  const convertedProperties = convertLegacyProperties(computedProperties);

  return {
    ...properties,
    ...convertedProperties
  };
};

const ConditionalPropertiesMigrateMap = {
  editable: "!readonly",
  enabled: "!disabled",
  visible: "visible"
};

const getConditionValue = ({ props, propKey }) => {
  const { properties = {}, rowData } = props;
  const rawVal = properties[propKey];

  if (typeof rawVal === "boolean") {
    return rawVal;
  }

  if (typeof rawVal === "string") {
    const result = getValueFromDataBindingFormat(rawVal, rowData);

    return result;
  }

  return undefined;
};

const convertLegacyProperties = properties => {
  return Object.entries(properties).reduce((acc, [key, value]) => {
    const newKey = ConditionalPropertiesMigrateMap[key];
    if (newKey.startsWith("!")) {
      acc[newKey.slice(1)] = !value;
      return acc;
    }
    acc[newKey] = value;
    return acc;
  }, {});
};
