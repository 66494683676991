// 추후 ToolbarV2를 사용하게 된다면, 해당 컴포넌트를 쓰는 부분을 전부 ToolbarV2ComponentBuilder로 변경하면 됨

import React, { ReactNode } from "react";
import { IComponentBuilderProps } from "@/types/common";
import ComponentBuilder from "@/builders/common/ComponentBuilder";
import { toolbarTypeMap } from "@/maps/toolbarTypeMap";
import { ITableData } from "@/types/table";

const ToolbarComponentBuilder = (
  props: IComponentBuilderProps & { tableInfo?: ITableData }
): ReactNode => {
  const { type } = props;

  return <ComponentBuilder {...props} type={toolbarTypeMap[type] || type} />;
};

export default ToolbarComponentBuilder;
