import React from "react";
import { ToolbarV2, ToolbarV2PropTypes } from "@ui5/webcomponents-react";

function FtToolbar(props: ToolbarV2PropTypes) {
  const { style, ...rest } = props;
  return (
    <ToolbarV2
      alignContent="Start"
      style={{ height: "auto", padding: "0.5rem 0", ...style }}
      {...rest}
    />
  );
}

FtToolbar.displayName = "Toolbar in ft-react";
export default FtToolbar;
