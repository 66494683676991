import React from "react";
import { Label, LabelPropTypes } from "@ui5/webcomponents-react";

interface ILabelProps extends LabelPropTypes {
  name?: string;
}

const FtLabel = (props: ILabelProps) => {
  return <Label wrappingType="Normal" {...props} />;
};

FtLabel.displayName = "Label in ft-react";
export default FtLabel;
