import InitialOptions from "@/builders/codeEditor/InitialCustomOptions";
import React, { createContext, useState } from "react";

const CustomOptions = [
  {
    name: "theme",
    label: "테마",
    options: ["vs", "vs-dark", "hc-black", "hc-light"]
  },
  {
    name: "fontSize",
    label: "글자크기",
    options: [10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30]
  },
  {
    name: "formatOnType",
    label: "입력 시 자동 포맷팅",
    options: [
      { label: "on", value: true },
      { label: "off", value: false }
    ]
  },
  {
    name: "minimapEnabled",
    label: "미니맵",
    options: [
      { label: "on", value: true },
      { label: "off", value: false }
    ]
  },
  {
    name: "wordWrap",
    label: "줄바꿈 설정",
    options: ["off", "on", "wordWrapColumn", "bounded"]
  }
];

const initialState = {
  selectedOptions: InitialOptions,
  CustomOptions,
  handleSelectOpt: () => {},
  isFullScreen: false,
  handleFullScreen: () => {}
};

interface ICodeEditorContext {
  selectedOptions: { [key: string]: any };
  CustomOptions: { name: string; label: string; options: any[] }[];
  handleSelectOpt: ({ name, value }: { name: string; value: any }) => void;
  isFullScreen: boolean;
  handleFullScreen: () => void;
}

export const CodeEditorContext = createContext<ICodeEditorContext>(
  initialState
);

export const CodeEditorContextProvider = ({ children }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    initialState.selectedOptions
  );
  const [isFullScreen, setIsFullScreen] = React.useState<boolean>(false);

  React.useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement !== null);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  function handleFullScreen() {
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  const handleSelectOpt = ({ name, value }) => {
    setSelectedOptions(options => {
      return {
        ...options,
        [name]: value
      };
    });
  };

  return (
    <CodeEditorContext.Provider
      value={{
        CustomOptions,
        selectedOptions,
        handleSelectOpt,
        isFullScreen,
        handleFullScreen
      }}
    >
      {children}
    </CodeEditorContext.Provider>
  );
};

export const useCodeEditorContext = () => React.useContext(CodeEditorContext);
