import React from "react";
import { CheckBox } from "@ui5/webcomponents-react";
import { defined } from "@bsgp/lib-core";

function FtCheckBox(props) {
  const { properties = {}, texts = {}, ...rest } = props;
  const value = defined(
    properties.checked,
    properties.selected,
    props.value,
    false
  );
  const handleSelect =
    properties.select ||
    properties.onSelect ||
    properties.onChange ||
    props.onChange;
  return (
    <CheckBox
      {...rest}
      {...properties}
      {...(handleSelect && {
        onChange: event => {
          event.preventDefault();
          return handleSelect(event);
        }
      })}
      checked={value}
      text={defined(properties.text, texts[value], "")}
    />
  );
}

export default React.memo(FtCheckBox);
