import React from "react";
import { Label } from "@/components";
import { isObject } from "@bsgp/lib-core";
import { IElement } from "@/types/form";
import { getComponentName } from "@/lib/utils";
import { IComponentBuilderProps } from "@/types/common";

function LabelBuilder({
  hasMultiComponents,
  element,
  elName,
  ...rest
}: {
  hasMultiComponents: boolean;
  element: IElement;
  elName: string;
}) {
  if (!element.field.label) return null;
  let labelFor = element.name;
  if (hasMultiComponents) {
    const components = element.field.component as IComponentBuilderProps[];
    const idx = components.findIndex(item => item.type === "Input");
    labelFor = getComponentName({
      elName,
      fieldComponent: element.field.component[Math.max(idx, 0)]
    });
  }
  if (isObject(element.field.label)) {
    const labelObj = element.field.label as { text?: string };
    return (
      <Label name={`${elName}_label`} for={labelFor} {...labelObj} {...rest}>
        {labelObj.text}
      </Label>
    );
  } else {
    return (
      <Label
        name={`${elName}_label`}
        for={labelFor}
        required={element.field.required || false}
        {...rest}
      >
        {element.field.label as string}
      </Label>
    );
  }
}

export default React.memo(LabelBuilder);
