import React from "react";
import { Toolbar } from "@/components";
import { ToolbarDesign } from "@ui5/webcomponents-react";
import ToolbarComponentBuilder from "../common/ToolbarComponentBuilder";
import { useNodeEditorInfo } from "@/hooks/nodeEditor";
import { INodeEditorBuilderProps } from "@/types/nodeEditor";

const ToolbarBuilder = (props: INodeEditorBuilderProps) => {
  const { toolbar } = props;
  const { nodeInfo } = useNodeEditorInfo();

  if (!toolbar) return null;
  const { content, properties } = toolbar;

  const nextId = nodeInfo.length + 1;

  return (
    <Toolbar
      design={ToolbarDesign.Transparent}
      {...properties}
      className={"sapUiSizeCompact"}
    >
      {content.map(bar => {
        return (
          <ToolbarComponentBuilder
            key={bar.name}
            {...bar}
            {...bar.component}
            nextId={nextId}
            refineEventHookName={"useRefineEventForNodeEditor"}
          />
        );
      })}
    </Toolbar>
  );
};

export default ToolbarBuilder;
