import React from "react";
import FooterComponentsBuilder from "./FooterComponentsBuilder";
import { Toolbar } from "@/components";
import { ToolbarDesign } from "@ui5/webcomponents-react";
import clsx from "clsx";
import { IFooterBuilderProps } from "@/types/footer";

const FooterBuilder = (props: IFooterBuilderProps) => {
  return (
    <Toolbar
      design={ToolbarDesign.Transparent}
      className={clsx({
        sapUiSizeCompact: true
      })}
      style={
        props.stickyFooter
          ? {
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "var(--sapContent_Illustrative_Color7)"
            }
          : {}
      }
    >
      <FooterComponentsBuilder {...props} />
    </Toolbar>
  );
};

export default FooterBuilder;
