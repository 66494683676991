import React from "react";
import { FileUploader } from "@ui5/webcomponents-react";

const FtFileUploader = props => {
  const { properties, style } = props;
  return (
    <FileUploader
      placeholder="선택된 파일이 없습니다."
      {...properties}
      style={style}
    />
  );
};

export default FtFileUploader;
