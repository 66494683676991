import React from "react";
import { Toolbar, ToolbarPropTypes } from "@ui5/webcomponents-react";
import { IComponentCommonProps } from "@/types/component";

function FtToolbar(props: ToolbarPropTypes & IComponentCommonProps) {
  const { style, ...rest } = props;
  return (
    <Toolbar
      style={{ height: "auto", padding: "0.5rem 0", ...style }}
      {...rest}
    />
  );
}

FtToolbar.displayName = "Toolbar in ft-react";
export default FtToolbar;
