import React, { useState } from "react";
import Icon from "@/components/Icon";
import { useTableContext } from "@/contexts/tableContext";
import { handleFilter } from "@/lib/utils";
import Input from "@/components/inputs/Input";
import { InputDomRef } from "@ui5/webcomponents-react";

interface ISearchHandlerBuilderProps {
  name: string;
}

const SearchField = ({ name, ...rest }: ISearchHandlerBuilderProps) => {
  const [searchValue, setSearchValue] = useState("");
  const { tableData, setFilteredData, setPage } = useTableContext();

  const handleSearch = () => {
    const filteredTableData = handleFilter({
      targetArray: tableData,
      filteringValue: searchValue
    });

    setFilteredData(filteredTableData);
    setPage(0);
  };

  return (
    <Input
      {...rest}
      name={`${name}_header_toolbar_content_searchHandler`}
      properties={{
        placeholder: "결과 내 검색",
        icon: <Icon name="search" interactive={true} onClick={handleSearch} />,
        onInput: event => {
          const currentTarget = event.currentTarget as InputDomRef;
          const searchValue = currentTarget.value;
          setSearchValue(searchValue);
        }
      }}
      style={{
        width: "10rem"
      }}
      onSubmit={handleSearch}
    />
  );
};

export default SearchField;
