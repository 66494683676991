import React from "react";
import { MultiInput, Token } from "@ui5/webcomponents-react";
import { IInputProps } from "@/types/component";

export interface IToken {
  text: string;
  key: string;
}

const FtMultiInput = (props: IInputProps) => {
  const { items } = props;
  // const itemProperties = items.properties;
  const itemList = items.list || [];

  const tokens = getTokenItems(itemList);
  // const suggestionItems = getListItemConfig({
  //   properties: itemProperties
  // });
  return (
    <MultiInput {...props} tokens={<>{tokens}</>} showSuggestions={true}>
      {/* {suggestionItems} */}
    </MultiInput>
  );
};

function getTokenItems(itemList) {
  // tokens를 받아 IToken의 배열로 가공한다.
  return itemList.map((itemText, index) => {
    return <Token text={itemText} key={`${index}-${itemText}`} />;
  });
}

export default FtMultiInput;
