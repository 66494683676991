var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var mods_exports = {};
__export(mods_exports, {
  MODES: () => MODES,
  extractAttributes: () => extractAttributes,
  getValueByPath: () => getValueByPath,
  splitSpecialKeyIfExists: () => splitSpecialKeyIfExists
});
module.exports = __toCommonJS(mods_exports);
const MODES = {
  CREATE: {},
  READ: {},
  UPDATE: {},
  DELETE: {}
};
const extractAttributes = (forms, tables, codeeditors = [], nodeeditors = []) => {
  const attrForms = forms && forms.filter((form) => form.ignore !== true).reduce((acc, form) => {
    const row = {
      id: ["forms", form.key].join("."),
      attributes: []
    };
    if (form.containers) {
      form.containers.filter((cont) => cont.ignore !== true).forEach((cont) => {
        if (cont.fields) {
          cont.fields.filter((fld) => fld.ignore !== true).forEach((fld) => {
            if (fld.components) {
              fld.components.filter((component) => component.ignore !== true).forEach((comp) => {
                row.attributes.push(comp.key || fld.key);
              });
            }
          });
        }
      });
    }
    row.attributes = row.attributes.join(", ");
    acc.push(row);
    return acc;
  }, []);
  const attrTables = tables && tables.filter((table) => table.ignore !== true).map((table) => {
    const row = {
      id: ["tables", table.key].join("."),
      attributes: []
    };
    if (table.columns) {
      table.columns.filter((col) => col.ignore !== true).forEach((col) => {
        if (col.components) {
          const colComps = col.components.filter(
            (comp) => comp.ignore !== true
          );
          if (colComps.length <= 1) {
            row.attributes.push(col.key);
          } else {
            colComps.filter((component) => component.ignore !== true).forEach((comp) => {
              row.attributes.push(comp.key);
            });
          }
        }
      });
    }
    row.attributes = row.attributes.join(", ");
    return row;
  });
  const attrCodeEditors = codeeditors.filter((ce) => ce.ignore !== true).map((codeeditor) => {
    const row = {
      id: ["codeeditors", codeeditor.key].join("."),
      attributes: ""
    };
    return row;
  });
  const attrNodeEditors = nodeeditors.filter((ne) => ne.ignore !== true).map((nodeeditor) => {
    const row = {
      id: ["nodeeditors", nodeeditor.key].join("."),
      attributes: [
        "id",
        "begin",
        "end",
        "{nodeId} : {id, position, name, next}"
      ].join(", ")
    };
    return row;
  });
  return [...attrForms, ...attrTables, ...attrCodeEditors, ...attrNodeEditors];
};
const getValueByPath = (data, path) => {
  if (path === void 0) {
    return void 0;
  }
  const pathArray = path.split(".");
  if (pathArray[0] === "state") {
    pathArray.shift();
  }
  try {
    return pathArray.reduce((acc, curr) => acc[curr], data);
  } catch (ex) {
    return void 0;
  }
};
const splitSpecialKeyIfExists = (key) => {
  if (!key.startsWith("properties-") && !key.startsWith("valueHelpV2-")) {
    return null;
  }
  const keyPrefix = key.startsWith("properties-") ? "properties" : "valueHelpV2";
  const propsKey = keyPrefix;
  const propsName = key.replace(`${keyPrefix}-`, "");
  return [propsKey, propsName];
};
