import React from "react";
import ListBuilder from "./ListBuilder";
import AnalyticalTableBuilder from "./AnalyticalTableBuilder";
import TableComponentBuilder from "./TableComponentBuilder";
import { IDynamicTableBuilderProps } from "@/types/table";
import { useFetchTable } from "@/hooks/table";

function DynamicTableBuilder(props: IDynamicTableBuilderProps) {
  const { tableName, data, header, footer } = props;
  useFetchTable({ tableName, data });

  if (data.isObjects) {
    return <ListBuilder />;
  }

  if (data.isTreeTable || data.useAnalyticalTable) {
    return <AnalyticalTableBuilder header={header} footer={footer} />;
  }

  return <TableComponentBuilder header={header} footer={footer} />;
}

export default DynamicTableBuilder;
