import React from "react";
import { TextArea } from "@ui5/webcomponents-react";

function FtTextArea(props) {
  const { properties = {}, value } = props;
  return (
    <TextArea
      {...properties}
      value={value || ""}
      style={{ height: "100%", ...props.style }}
    />
  );
}

export default React.memo(FtTextArea);
