import React from "react";
import {
  RadioButton,
  RadioButtonDomRef,
  Ui5CustomEvent
} from "@ui5/webcomponents-react";
import { IRadioProps } from "@/types/component";

type RadioOnChangeType = (
  event: Ui5CustomEvent<RadioButtonDomRef, never>
) => void;

const FtRadioButton = (props: IRadioProps) => {
  const { properties, list, style, id, name, ...rest } = props;
  const radioName = id || name;
  if (!list || !Array.isArray(list) || !radioName) return null;
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {list.map(({ key, text }, idx) => {
        const onChange = (properties.onChange ||
          properties.onSelect) as RadioOnChangeType;
        return (
          <RadioButton
            {...rest}
            {...properties}
            onChange={onChange}
            key={`${key}_${idx}`}
            name={radioName}
            text={text}
            value={key}
          />
        );
      })}
    </div>
  );
};

export default FtRadioButton;
