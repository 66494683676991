import React, { ReactNode } from "react";
import { defined } from "@bsgp/lib-core";
import { FormGroup } from "@/components";
import FormItemBuilder from "./FormItemBuilder";
import { IFormObject } from "@/types/form";

interface IFormGroupBuilderProps {
  formObject: IFormObject;
  formName: string;
  sizeV2?: boolean;
}

function FormGroupBuilder({
  formObject,
  formName,
  sizeV2
}: IFormGroupBuilderProps): ReactNode {
  return formObject.containers.map(ctn => {
    const containerName = [formName, ctn.name].join("-");
    const properties = defined(ctn.properties, {});
    return (
      <FormGroup
        name={containerName}
        key={containerName}
        title={ctn.title}
        {...properties}
      >
        {ctn.elements
          .map(element => {
            const elName = [containerName, element.name].join("-");
            return (
              <FormItemBuilder
                key={elName}
                element={element}
                containerName={containerName}
                sizeV2={sizeV2}
              />
            );
          })
          .filter(Boolean)}
      </FormGroup>
    );
  });
}

export default FormGroupBuilder;
