import React, { useEffect, memo } from "react";
import {
  FlexBoxAlignItems,
  FlexBoxJustifyContent
} from "@ui5/webcomponents-react";
import { convertController } from "@bsgp/lib-hoc/src/modules";
import VBox from "@/components/layouts/VBox";
import { clsx } from "clsx";
import store from "@bsgp/lib-api/store";
import { ICommonProps } from "@/types/common";
import HistoryConstructor from "@/builders/historyLink/Constructor";
import FormConstructor from "@/builders/form/FormConstructor";
import TableConstructor from "@/builders/table/TableConstructor";
import FooterConstructor from "@/builders/footer/FooterConstructor";
import DialogV2Constructor from "@/builders/dialogV2/DialogV2Constructor";
import NodeEditorConstructor from "@/builders/nodeEditor/Constructor";
import { titleRef } from "title";
import { setPropsRef } from "@/ref";
import CodeEditorConstructor from "@/builders/codeEditor/Constructor";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  main: {
    height: "100%",
    boxSizing: "border-box",
    "& *": {
      boxSizing: "border-box"
    },
    "& .react-flow *": {
      boxSizing: "initial"
    }
  }
});

const FormAndTableBuilder = (props: ICommonProps) => {
  console.log("🚀 ~ props:", props);
  const { data, fn, title } = props;
  const { center = false, hCenter = false, vCenter = false } = data;
  const classes = useStyles();

  setPropsRef({
    props,
    fn: convertController(fn, store.getState(), props, () => props)
  });

  useEffect(() => {
    document.title = title || "BSG Support";
    titleRef.current.textContent = title || "BSG Support";
  }, [title]);

  return (
    <main className={classes.main}>
      <HistoryConstructor {...props} />
      <FooterConstructor {...props} />
      {(data.form || data.table || data.codeeditor || data.nodeeditor) && (
        <VBox
          name={"wrapper"}
          {...(hCenter !== false && {
            alignItems: FlexBoxAlignItems.Center
          })}
          {...((center || vCenter) && {
            justifyContent: FlexBoxJustifyContent.Center
          })}
          fitContainer={true}
          className={clsx({
            "ft-height-fill-available": !!data.codeeditor,
            "ft-height-fit-content": !data.codeeditor
          })}
        >
          <VBox
            name={"innerWrapper"}
            fitContainer={true}
            className="max-height-100p"
          >
            <FormConstructor {...props} />
            <TableConstructor {...props} />
            <CodeEditorConstructor {...props} />
            <NodeEditorConstructor {...props} />
          </VBox>
        </VBox>
      )}
      <DialogV2Constructor {...props} />
    </main>
  );
};

// function getCircularReplacer() {
//   const seen = new WeakSet();
//   return (key, value) => {
//     if (typeof value === "object" && value !== null) {
//       if (seen.has(value)) {
//         return;
//       }
//       seen.add(value);
//     }
//     return value;
//   };
// }

export default memo(FormAndTableBuilder);
