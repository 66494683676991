import React from "react";
import { Table } from "@ui5/webcomponents-react";

const FtTable = props => {
  return <Table {...props} />;
};

FtTable.displayName = "Table in ft-react";

export default React.memo(FtTable);
