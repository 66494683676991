import React, { useEffect } from "react";
import { ITableData } from "@/types/table";
import { getMode, filterFn } from "@/lib/tableUtils";
import {
  AnalyticalTableSelectionBehavior,
  TableGrowingMode,
  TextAlign
} from "@ui5/webcomponents-react";
import clsx from "clsx";
import { convertNumber } from "@bsgp/form-and-table/fieldComponent";
import * as all from "@bsgp/form-and-table";
import { IComponentBuilderProps } from "@/types/common";
import CellBuilder from "@/builders/table/CellBuilder";
import { Text, Filter } from "@/components";
import { useTableContext } from "@/contexts/tableContext";

const { getNewValue, getNewName, isBound } = all;

interface IProp {
  tableName: string;
  data: ITableData;
}

export const useFetchTable = ({ tableName, data }: IProp) => {
  const {
    setTableData,
    setTableProperties,
    setFilteredData
  } = useTableContext();

  useEffect(() => {
    const { newMapConv, newColumns } = getMapConvAndColumns(data, tableName);
    const newProperties = getTableProperties(tableName, data);
    setTableProperties({
      ...newProperties,
      columnProperties: {
        columns: newColumns || [],
        name: tableName
      }
    });
    const newData = getTableData(data, newMapConv);
    setTableData(newData);
    setFilteredData(newData);
  }, [tableName, data]);
};

export const useSetPage = pageInfo => {
  const { setPage, setItemsPerPage, setEdge } = useTableContext();

  useEffect(() => {
    if (!pageInfo) return;
    const actualInitialPage = pageInfo.initial - 1;
    setPage(actualInitialPage);
    setItemsPerPage(pageInfo.itemsPerPage);
    setEdge(pageInfo.edge);
  }, [pageInfo]);
};

function getMapConvAndColumns(tableInfo: ITableData, tableName: string) {
  const newMapConv = {};

  const newColumns = tableInfo.columns.map(col => {
    if (col.conv) {
      newMapConv[col.name] = col.conv;
    }
    const colComponent = col.component as IComponentBuilderProps;
    if (!colComponent) return col;
    if (colComponent.type === "ObjectNumber") {
      col.hAlign = TextAlign.End;
    }
    return {
      ...col,
      ...(col.showSum ? { footerText: getFooterText(tableInfo, col) } : {}),
      ...(colComponent.type === "ObjectNumber"
        ? { hAlign: TextAlign.End }
        : {}),
      ...(tableInfo.isTreeTable || tableInfo.useAnalyticalTable
        ? getAnalyticalTableColumns(col, tableInfo, tableName)
        : {})
    };
  });
  return { newMapConv, newColumns };
}

function getFooterText(tableInfo: ITableData, col) {
  const colComponent = col.component as IComponentBuilderProps;

  let unit = colComponent.properties.unit as string;
  let unitIsBound = false;

  if (isBound(unit)) {
    unit = unit.replace("{", "").replace("}", "");
    unitIsBound = true;
  }

  const footerText = tableInfo.items.list.reduce((acc, item) => {
    if (unitIsBound) {
      if (acc[item[unit]] === undefined) {
        acc[item[unit]] = 0;
      }
      acc[item[unit]] += parseFloat(item[col.name]);
    } else {
      if (acc[unit] === undefined) {
        acc[unit] = 0;
      }
      acc[unit] += parseFloat(item[col.name]);
    }

    return acc;
  }, {});
  return Object.keys(footerText)
    .map(key => {
      return [
        convertNumber(footerText[key], key, {
          isAmount: colComponent.properties.isAmount,
          isQuantity: colComponent.properties.isQuantity,
          asA1: colComponent.properties.asA1
        }),
        key
      ].join(" ");
    })
    .join("\n");
}

function getTableProperties(tableName: string, tableInfo: ITableData) {
  if (tableInfo.isTreeTable) {
    return getTreeTableProperties(tableName, tableInfo);
  }
  const mode = getMode(tableInfo);

  return {
    name: tableName,
    isTreeTable: false,
    noDataText: "데이터 없음",
    style: { width: "auto" },
    growing: TableGrowingMode.None,
    // growing: TableGrowingMode.Button,
    ...tableInfo.properties,
    mode,
    className: clsx({
      "select-all": true,
      "no-indent-row": true,
      sapUiSizeCompact: tableInfo.compactSize === true,
      "allow-scroll": tableInfo.allowScroll === true,
      "alt-table-style": true
    }),
    onSelect: tableInfo.onSelect,
    onDrop: tableInfo.onDrop,
    onSelectTab: tableInfo.onSelectTab,
    onSort: tableInfo.onSort
  };
}

function getTableData(tableInfo: ITableData, mapConv: object) {
  return tableInfo.items.list.map(item => {
    const newItem = {
      ...item
    };
    Object.keys(mapConv).forEach(colName => {
      newItem[colName] = getNewValue(newItem[colName], mapConv[colName]);
    });
    if (!newItem.properties) {
      newItem.properties = {};
    }
    if (!newItem.properties.row) {
      newItem.properties.row = {};
    }
    if (!newItem.properties.cell) {
      newItem.properties.cell = Object.keys(item || {})
        .filter(key => !["properties", "selected", "selectable"].includes(key))
        .reduce((acc, key) => {
          acc[key] = {};
          return acc;
        }, {});
    }
    return newItem;
  });
}

function getTreeTableProperties(tableName, tableInfo) {
  const mode = getMode(tableInfo);

  return {
    name: tableName,
    isTreeTable: true,
    selectionBehavior: AnalyticalTableSelectionBehavior.Row,
    selectionMode: mode,
    subRowsKey: "$sub$",
    className: clsx({ "ft-sticky-columns": tableInfo.stickyColumns === true })
  };
}

function getAnalyticalTableColumns(column, tableInfo, tableName) {
  const { width, minWidth, maxWidth, ...rest } = column;
  const cellName = getNewName([tableName, column.name].join("-"));
  return {
    disableDragAndDrop: !tableInfo.onDrop,
    accessor: column.name || column.value,
    // Header: column.text,
    Header: (
      <Text
        {...(tableInfo.headerNoWrap === true
          ? {
              maxLines: 1,
              emptyIndicator: true
            }
          : {})}
      >
        {column.text}
      </Text>
    ),
    Cell: cellProps => {
      const { cell } = cellProps;
      const value = cell.value;
      const rowData = cell.row.original;
      return (
        <CellBuilder
          column={column}
          cellName={cellName}
          value={value}
          rowData={rowData}
        />
      );
    },
    hAlign: column.hAlign || TextAlign.Begin,
    disableGroupBy: true,
    disableSortBy: true,
    disableResizing: true,
    disableFilters: true,
    disableGlobalFilter: true,
    ...rest,
    Filter: ({ column }) => <Filter column={column} />,
    filter: filterFn
  };
}

export default useTableContext;
