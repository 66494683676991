import { Text } from "@/components";
import React from "react";
import { useTableContext } from "@/contexts/tableContext";

const DataCntInfo = () => {
  const { filteredData, itemsPerPage, page } = useTableContext();
  const total = filteredData.length;
  const displayingPage = page + 1;
  const from =
    total === 0 ? 0 : itemsPerPage * displayingPage - itemsPerPage + 1;
  const to =
    total < itemsPerPage * displayingPage
      ? total
      : itemsPerPage * displayingPage;
  const text = `${total}개 중 ${from}-${to}`;
  return <Text>{text}</Text>;
};

export default DataCntInfo;
