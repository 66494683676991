import React from "react";

import { compose } from "@reduxjs/toolkit";
import { render5 } from "@bsgp/lib-hoc";

import * as ui5 from "./index.ui5";
import * as common from "./common";
import { formTable } from "./formTable";
import { ft } from "./ft";
import * as functions from "./lib/functions";
import * as fieldComponent from "./fieldComponent";

import { useI18nBundle } from "@ui5/webcomponents-react-base";

export const {
  getNewValue,
  constructFooterData,
  constructFormData,
  constructNodeEditorData,
  getNewName,
  constructTableData
} = common;
export const { removeUndefinedKeys, isBound, extractArgs } = functions;
export const { convertNumber, escapeValue } = fieldComponent;

const refI18n = React.createRef();
refI18n.current = { getText: key => key };
function Comp() {
  return true;
}
Comp.displayName = "FormTable";

const FormTable = compose(render5(ui5))(Comp);

export { FormTable, formTable, ft, functions, useI18nBundle, refI18n };
