import React, { ReactNode } from "react";
import { defined } from "@bsgp/lib-core";
import { FormItem, HBox } from "@/components";
import { isArray } from "@bsgp/lib-core";
import { FlexBoxAlignItems, FlexBoxWrap } from "@ui5/webcomponents-react";
import FormComponentIterator from "./FormComponentIterator";
import { IElement } from "@/types/form";
import LabelBuilder from "./LabelBuilder";

interface IFormItemBuilderProps {
  element: IElement;
  containerName: string;
  sizeV2?: boolean;
}

function FormItemBuilder({
  element,
  containerName,
  sizeV2
}: IFormItemBuilderProps): ReactNode {
  const elName = [containerName, element.name].join("-");
  const hasMultiComponents = isArray(element.field.component);
  const inHBox = defined(element.field.inHBox, hasMultiComponents);
  let noWrap = false;
  if (sizeV2 === true) {
    noWrap = true;
    if (element.field.noWrap === false) {
      noWrap = false;
    }
  } else if (element.field.noWrap !== false) {
    noWrap = true;
  }

  const getIterator = () => {
    return (
      <FormComponentIterator
        hasMultiComponents={hasMultiComponents}
        element={element}
        elName={elName}
      />
    );
  };

  return (
    <FormItem
      name={elName}
      visible={defined(element.field.visible, true)}
      label={
        <LabelBuilder
          hasMultiComponents={hasMultiComponents}
          element={element}
          elName={elName}
        />
      }
    >
      {inHBox ? (
        <HBox
          name={elName}
          wrap={noWrap ? FlexBoxWrap.NoWrap : FlexBoxWrap.Wrap}
          className={"tiny-margin-items"}
          vAlign={FlexBoxAlignItems.Center}
          style={{ gap: "0.5rem", width: "100%" }}
        >
          {getIterator()}
        </HBox>
      ) : (
        getIterator()
      )}
    </FormItem>
  );
}

export default FormItemBuilder;
