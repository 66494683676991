import React from "react";
import {
  Breadcrumbs,
  BreadcrumbsItem,
  BreadcrumbsPropTypes
} from "@ui5/webcomponents-react";

interface IFtBreadcrumbsProps extends BreadcrumbsPropTypes {
  items: Array<{ text: string; href: string }>;
}

const FtBreadcrumbs = (props: IFtBreadcrumbsProps) => {
  const { items } = props;
  return (
    <Breadcrumbs {...props}>
      {items.map((link, index) => {
        return (
          <BreadcrumbsItem key={index} href={link.href}>
            {link.text}
          </BreadcrumbsItem>
        );
      })}
    </Breadcrumbs>
  );
};

export default FtBreadcrumbs;
