import React, { memo } from "react";
import { DatePicker as DatePickerWCR } from "@ui5/webcomponents-react";
import { IDatePickerProps } from "@/types/component";

function DatePicker(props: IDatePickerProps) {
  const { properties = {}, ...rest } = props;
  const formatPattern = props.displayFormat || props.format || "yyyy-MM-dd";

  return (
    <DatePickerWCR {...properties} {...rest} formatPattern={formatPattern} />
  );
}

export default memo(DatePicker);
