import React from "react";
import Editor from "@monaco-editor/react";
import { defined } from "@bsgp/lib-core";

const convertAceOptionToMonaco = properties => {
  const { editable, syntaxHints, ...restProperties } = properties;
  return {
    ...restProperties,
    readOnly: editable === false,
    quickSuggestions: syntaxHints !== false,
    suggestOnTriggerCharacters: syntaxHints !== false
  };
};

const CodeEditor = props => {
  const { value, options = {}, properties = {} } = props;
  const { onChange = () => {}, ...restProperties } = properties;
  const convertedOptions = convertAceOptionToMonaco(restProperties);
  return (
    <Editor
      height="100%"
      value={value}
      language={properties.type || "javascript"}
      options={{
        wordWrap: convertedOptions.readOnly ? "on" : "off",
        scrollBeyondLastLine: false,
        ...options,
        ...convertedOptions,
        minimap: {
          enabled: defined(properties.minimapEnabled, options.minimapEnabled)
        }
      }}
      {...restProperties}
      onChange={editorVal => {
        onChange({
          componentCtg: "codeeditors",
          value: editorVal
        });
      }}
    />
  );
};

export default CodeEditor;
