import { useContext, useEffect, useCallback, useRef } from "react";
import { NodeEditorContext } from "@/contexts/nodeEditorContext";
import { propsRef } from "@/ref";
import {
  addEdge,
  applyNodeChanges,
  useEdgesState,
  reconnectEdge
} from "reactflow";
import {
  CustomEdge,
  CustomNode,
  INodeEditorProps,
  INodeJson,
  IRawNodeValue
} from "@/types/nodeEditor";

export const useInitializeNodeEditor = props => {
  const { initializeNodeEditor } = useContext(NodeEditorContext);
  const fn = propsRef.current.fn;

  useEffect(() => {
    if (!fn) return;
    initializeNodeEditor(props);
  }, [fn]);
};

export const useNodeEditorInfo = () => useContext(NodeEditorContext);

export const useNodes = ({
  onChange,
  onNodeDoubleClick: onNodeDblClick
}: INodeEditorProps) => {
  const { nodeInfo, edgeInfo, rawInfo } = useNodeEditorInfo();

  const onNodesChange = useCallback(
    changes => {
      // dimensions change는 렌더링 시 자동으로 생기는 것이므로 무시함
      const realChanges = changes.filter(
        change => change.type !== "dimensions" && change.type !== "select"
      );
      if (realChanges.length === 0) return;

      const newNodes = applyNodeChanges(realChanges, nodeInfo);
      onChange({
        componentCtg: "nodeeditors",
        value: convertNodeEditorToJSON({
          nodeInfo: newNodes,
          edgeInfo,
          initialNode: rawInfo
        })
      });
    },
    [edgeInfo, nodeInfo, onChange, rawInfo]
  );

  const onNodeDoubleClick = useCallback(onNodeDblClick || (() => {}), [
    onNodeDblClick
  ]);

  return { nodes: nodeInfo, onNodesChange, onNodeDoubleClick };
};

export const useEdges = ({ onChange }: INodeEditorProps) => {
  const { nodeInfo, edgeInfo, rawInfo } = useNodeEditorInfo();
  const [edges, setEdges, onEdgesChange] = useEdgesState(edgeInfo);
  const reconnectDone = useRef(true);
  const onConnect = useCallback(
    params => setEdges(els => addEdge(params, els)),
    []
  );
  useEffect(() => {
    onChange({
      componentCtg: "nodeeditors",
      value: convertNodeEditorToJSON({
        nodeInfo,
        edgeInfo: edges,
        initialNode: rawInfo
      })
    });
  }, [edges]);

  const onReconnectStart = useCallback(() => {
    reconnectDone.current = false;
  }, []);

  const onReconnect = useCallback((oldEdge, newConnection) => {
    reconnectDone.current = true;
    setEdges(els => reconnectEdge(oldEdge, newConnection, els));
  }, []);

  const onReconnectEnd = useCallback((some, edge) => {
    if (!reconnectDone.current) {
      setEdges(eds => eds.filter(ed => ed.id !== edge.id));
    }

    reconnectDone.current = true;
  }, []);

  return {
    edges,
    onConnect,
    onEdgesChange,
    onReconnect,
    onReconnectStart,
    onReconnectEnd
  };
};

const convertNodeEditorToJSON = (params: {
  nodeInfo: CustomNode;
  edgeInfo: CustomEdge;
  initialNode: INodeJson;
}): INodeJson => {
  const { nodeInfo, edgeInfo, initialNode } = params;
  const newJson = { ...initialNode };

  nodeInfo.forEach(node => {
    const key = node.id;
    const value: IRawNodeValue = {
      id: node.data.id,
      name: node.data.name,
      position: [node.position.x, node.position.y]
    };

    const nextEdge = edgeInfo.find(edge => edge.source === key);
    if (nextEdge) {
      value.next = nextEdge.target;
    }

    newJson[key] = value;
  });

  return newJson;
};
