import { extractArgs, removeUndefinedKeys } from "./lib/functions";
import { tryit, isArray, isObject } from "@bsgp/lib-core";

function addButton(key, options, targetObj) {
  const isMultiple = isArray(options) && options.length > 1;
  if (isMultiple) {
    targetObj[key] = {
      value: options[0].text,
      component: {
        type: "MenuButton",
        properties: {
          icon: options[0].icon,
          ...options[0].properties,
          useDefaultActionOnly: true,
          ...(options[0].isMain === false
            ? { type: window.sap.m.ButtonType.Ghost }
            : { type: window.sap.m.ButtonType.Emphasized }),
          ...(options[0].onPress
            ? {
                buttonMode: window.sap.m.MenuButtonMode.Split,
                defaultAction: options[0].onPress
              }
            : {})
        }
      },
      items: options
        .filter((each, index) => index > 0)
        .reduce((acc, each, index) => {
          acc[`${key}_menuitem_${index}`] = {
            value: each.text,
            properties: {
              icon: each.icon,
              ...each.properties,
              press: each.onPress
            }
          };
          return acc;
        }, {})
    };
  } else {
    const oneOptions = isArray(options) ? options[0] : options;
    if (oneOptions.type === "FileUploader") {
      targetObj[key] = {
        component: {
          type: oneOptions.type,
          properties: {
            icon: oneOptions.icon,
            buttonOnly: true,
            buttonText: oneOptions.text || oneOptions.value,
            change: oneOptions.onPress,
            style:
              tryit(() => oneOptions.properties.type) ||
              window.sap.m.ButtonType.Ghost,
            ...oneOptions.properties
          }
        }
      };
    } else {
      const buttonType =
        oneOptions.isMain === true
          ? window.sap.m.ButtonType.Emphasized
          : window.sap.m.ButtonType.Ghost;
      targetObj[key] = {
        value: oneOptions.text || oneOptions.value,
        component: {
          type: "Button",
          confirmMessage: oneOptions.confirmMessage,
          properties: {
            press: oneOptions.onPress,
            icon: oneOptions.icon,
            type: oneOptions.type || buttonType,
            ...oneOptions.properties
          }
        }
      };
    }

    if (oneOptions.description) {
      targetObj[`${key}__desc`] = {
        value: oneOptions.description,
        component: {
          type: "Text"
        }
      };
    }
  }
}

function formTableUtil() {
  const util = {
    addForm: (data, key, options) => {
      if (!data.form) {
        data.form = {};
      }

      if (!key) {
        // data.form = { ...data.form, ...options };
        return data;
      }

      data.form[key] = { containers: {}, ...options };
      return data;
    },
    addContainer: (data, formKey, key, options) => {
      if (!key) {
        return data;
      }

      // if formKey = false, then it means the form will be invisible
      if (formKey === false) {
        return data;
      }

      if (!formKey) {
        if (!data.form.containers) {
          data.form.containers = {};
        }
        data.form.containers[key] = options;

        return data;
      }

      if (!data.form[formKey].containers) {
        data.form[formKey].containers = {};
      }
      data.form[formKey].containers[key] = { ...options, elements: {} };

      return data;
    },
    addField: (data, formKey, containerKey, key, options) => {
      if (!key) {
        return data;
      }

      // if formKey = false, then it means the form will be invisible
      if (formKey === false || containerKey === false) {
        return data;
      }

      if (!formKey) {
        if (data.form.containers) {
          if (containerKey) {
            if (!data.form.containers[containerKey].elements) {
              data.form.containers[containerKey].elements = {};
            }
            data.form.containers[containerKey].elements[key] = options;
            return data;
          }
          if (!data.form.containers.elements) {
            data.form.containers.elements = {};
          }
          data.form.containers.elements[key] = options;
          return data;
        }

        data.form[key] = options;
        return data;
      }
      if (!containerKey) {
        if (!data.form[formKey].containers) {
          data.form[formKey].containers = {};
        }
        if (!data.form[formKey].containers) {
          data.form[formKey].containers = {};
        }
        data.form[formKey].containers[key] = options;
        return data;
      }

      if (!data.form[formKey].containers[containerKey].elements) {
        data.form[formKey].containers[containerKey].elements = {};
      }
      data.form[formKey].containers[containerKey].elements[key] = options;

      return data;
    },
    addTable: (data, key, options = {}) => {
      if (!data.table) {
        data.table = {};
      }

      if (!key) {
        return data;
      }

      data.table[key] = {
        component: {
          columns: {},
          items: [],
          ...options
        }
      };

      if (data.table[key].component.usePagination !== undefined) {
        if (data.table[key].component.usePagination === true) {
          data.table[key].component.usePagination = {};
        }
        if (isObject(data.table[key].component.usePagination)) {
          data.table[key].component.usePagination = {
            ...{ initial: 1, itemsPerPage: 20, edge: 2 },
            ...data.table[key].component.usePagination
          };
        }
      }
      return data;
    },
    addToolbarButton: (data, tableKey, key, options) => {
      if (!key) {
        return data;
      }

      if (!data.table[tableKey].component.toolbar) {
        data.table[tableKey].component.toolbar = {};
      }

      if (!data.table[tableKey].component.toolbar.content) {
        data.table[tableKey].component.toolbar.content = {};
      }

      addButton(key, options, data.table[tableKey].component.toolbar.content);

      return data;
    },
    addToolbarAction: (data, tableKey, key, options) => {
      if (!key) {
        return data;
      }

      if (!data.table[tableKey].component.toolbar) {
        data.table[tableKey].component.toolbar = {};
      }

      if (!data.table[tableKey].component.toolbar.actions) {
        data.table[tableKey].component.toolbar.actions = {};
      }

      data.table[tableKey].component.toolbar.actions[key] = {
        value: options.text,
        confirmMessage: options.confirmMessage,
        properties: {
          ...options.properties,
          press: options.onPress
        }
      };

      return data;
    },
    addToolbarSearch: (data, tableKey, key, options) => {
      if (!data.table[tableKey].component.toolbar) {
        data.table[tableKey].component.toolbar = {};
      }

      const searchKey = ["search", key].filter(Boolean).join("_");
      data.table[tableKey].component.toolbar[searchKey] = {
        value: options.value,
        targetColumns: options.targetColumns,
        properties: removeUndefinedKeys({
          ...options.properties,
          change: options.onChange,
          search: options.onSearch
        })
      };

      return data;
    },
    addColumn: (data, tableKey, key, options) => {
      if (!key) {
        return data;
      }

      if (!data.table[tableKey].component.columns) {
        data.table[tableKey].component.columns = {};
      }

      data.table[tableKey].component.columns[key] = {
        ...options,
        properties: {
          width: options.width,
          ...options.properties
        }
      };

      return data;
    },
    addItems: (data, tableKey, list = []) => {
      data.table[tableKey].component.items = list;

      return data;
    },
    addFooterButton: (data, key, options) => {
      if (!key) {
        return data;
      }

      if (!data.footer) {
        data.footer = {};
      }

      addButton(key, options, data.footer);

      return data;
    },
    addFooterAction: (data, key, options) => {
      if (!key) {
        return data;
      }

      if (!data.footer) {
        data.footer = {};
      }

      if (!data.footer.actions) {
        data.footer.actions = {};
      }

      data.footer.actions[key] = {
        value: options.text || options.value,
        confirmMessage: options.confirmMessage,
        properties: {
          press: options.onPress,
          icon: options.icon,
          ...options.properties
        }
      };

      return data;
    },
    addLink: (data, key, options) => {
      // if (!key) {
      //   return data;
      // }

      if (!data.historyLinks) {
        data.historyLinks = [];
      }

      data.historyLinks.push(options);

      return data;
    },
    addCode: (data, key, options) => {
      // if (!key) {
      //   return data;
      // }

      if (!data.codeeditor) {
        data.codeeditor = {};
      }

      const codeeditor = {
        ...options
      };

      if (codeeditor.properties === undefined) {
        codeeditor.properties = {};
      }

      if (options.onChange) {
        codeeditor.properties = {
          ...codeeditor.properties,
          change: options.onChange
        };
      }

      if (options.type) {
        codeeditor.properties.type = options.type;
      }

      if (codeeditor.properties.syntaxHints === undefined) {
        codeeditor.properties.syntaxHints = false;
      }

      data.codeeditor[key || "codeeditor"] = codeeditor;

      return data;
    },
    addNode: (data, key, options) => {
      if (!data.nodeeditor) {
        data.nodeeditor = {};
      }

      const nodeeditor = {
        ...options
      };

      if (nodeeditor.properties === undefined) {
        nodeeditor.properties = {};
      }

      if (options.onChange) {
        nodeeditor.properties = {
          ...nodeeditor.properties,
          change: options.onChange
        };
      }

      if (options.type) {
        nodeeditor.properties.type = options.type;
      }

      data.nodeeditor[key || "nodeeditor"] = nodeeditor;

      return data;
    },
    addDialog: (data, key, options) => {
      if (!key) {
        return data;
      }

      if (!data.dialogV2) {
        data.dialogV2 = {};
      }

      data.dialogV2[key] = {
        properties: {},
        ftData: {},
        ...options,
        fullSize:
          options.fullSize === undefined
            ? true
            : options.fullSize === true
            ? true
            : false
      };

      return data;
    }
  };
  return util;
}

function formTable(options = {}) {
  let data = {
    ...options
  };

  const util = formTableUtil();

  const chain = {
    addForm: (...args) => {
      const [key, options] = extractArgs(args);

      data = util.addForm(data, key, options);
      chain.formKey = key;
      chain.containerKey = undefined;
      return chain;
    },
    addContainer: (...args) => {
      const [key, options] = extractArgs(args);

      data = util.addContainer(data, chain.formKey, key, options);
      chain.containerKey = key;
      return chain;
    },
    addField: (...args) => {
      const [key, options] = extractArgs(args);

      data = util.addField(
        data,
        chain.formKey,
        chain.containerKey,
        key,
        options
      );

      return chain;
    },
    addDialog: (...args) => {
      const [key, options] = extractArgs(args);

      data = util.addDialog(data, key, options);
      return chain;
    },
    addTable: (...args) => {
      const [key, options] = extractArgs(args);
      data = util.addTable(data, key, options);
      chain.tableKey = key;
      return chain;
    },
    addToolbarButton: (...args) => {
      const [key, options] = extractArgs(args);

      data = util.addToolbarButton(data, chain.tableKey, key, options);
      return chain;
    },
    addToolbarAction: (...args) => {
      const [key, options] = extractArgs(args);

      data = util.addToolbarAction(data, chain.tableKey, key, options);
      return chain;
    },
    addToolbarSearch: (...args) => {
      const [key, options] = extractArgs(args);

      data = util.addToolbarSearch(data, chain.tableKey, key, options);
      return chain;
    },
    addColumn: (...args) => {
      const [key, options] = extractArgs(args);

      data = util.addColumn(data, chain.tableKey, key, options);
      return chain;
    },
    addItems: items => {
      data = util.addItems(data, chain.tableKey, items);
      return chain;
    },
    addFooterButton: (...args) => {
      const [key, options] = extractArgs(args);
      data = util.addFooterButton(data, key, options);
      return chain;
    },
    addFooterAction: (...args) => {
      const [key, options] = extractArgs(args);
      data = util.addFooterAction(data, key, options);
      return chain;
    },
    addLink: (...args) => {
      const [key, options] = extractArgs(args);
      data = util.addLink(data, key, options);
      return chain;
    },
    addCode: (...args) => {
      const [key, options] = extractArgs(args);
      data = util.addCode(data, key, options);
      return chain;
    },
    addNode: (...args) => {
      const [key, options] = extractArgs(args);
      data = util.addNode(data, key, options);
      return chain;
    },
    done: () => {
      return data;
    }
  };
  return chain;
}

export { formTable };
