import React, { createContext, useMemo, useState } from "react";
import { ITableProperties } from "@/types/table";

interface ITableContext {
  tableData: any[];
  setTableData: (tableData: any[]) => void;
  page: number;
  setPage: (page: number) => void;
  filteredData: any[];
  setFilteredData: (filteredData: any[]) => void;
  tableProperties: ITableProperties;
  setTableProperties: (newProperties: ITableProperties) => void;
  itemsPerPage: number;
  setItemsPerPage: (itemsPerPage: number) => void;
  edge: number;
  setEdge: (edge: number) => void;
  selectedIndices: string[];
  setSelectedIndices: (selectedIndices: string[]) => void;
  dataDisplaying: any[];
}

const initialState: ITableContext = {
  tableData: [],
  setTableData: tableData => {},
  page: 0,
  setPage: (page: number) => {},
  filteredData: [],
  setFilteredData: filteredData => {},
  tableProperties: {
    columnProperties: {
      columns: []
    }
  },
  setTableProperties: (newProperties: ITableProperties) => {},
  itemsPerPage: 0,
  setItemsPerPage: (itemsPerPage: number) => {},
  edge: 2,
  setEdge: (edge: number) => {},
  selectedIndices: [],
  setSelectedIndices: () => {},
  dataDisplaying: []
};

export const TableContext = createContext(initialState);

export const TableContextProvider = props => {
  const [tableData, setTableData] = useState([]);
  const [tableProperties, setTableProperties] = useState<ITableProperties>({
    columnProperties: {
      columns: []
    }
  });
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [edge, setEdge] = useState(2);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]);

  const dataDisplaying = useMemo(() => {
    return itemsPerPage > 0
      ? filteredData.slice(page * itemsPerPage, (page + 1) * itemsPerPage)
      : filteredData;
  }, [itemsPerPage, filteredData, page]);

  return (
    <TableContext.Provider
      value={{
        tableData,
        setTableData,
        tableProperties,
        setTableProperties,
        page,
        setPage,
        itemsPerPage,
        setItemsPerPage,
        edge,
        setEdge,
        filteredData,
        setFilteredData,
        selectedIndices,
        setSelectedIndices,
        dataDisplaying
      }}
    >
      {props.children}
    </TableContext.Provider>
  );
};

export const useTableContext = () => React.useContext(TableContext);
