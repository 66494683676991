import { tryit } from "@bsgp/lib-core";

function getButtonSchemes(pageNum = 1, totalPage = 0, edge = 1) {
  const options = {
    count: totalPage,
    mapping: idx => idx + 1
  };

  const maxPageLength = edge * 2 + 1;

  if (totalPage > maxPageLength) {
    options.count = maxPageLength;
    const start = pageNum - edge;
    const end = pageNum + edge;
    if (start > 1 && end < totalPage) {
      options.mapping = idx => idx + start;
    } else if (end >= totalPage) {
      options.mapping = idx => idx + totalPage - maxPageLength + 1;
    }
  }

  const pageNumbers = new Array(options.count)
    .fill()
    .map((val, idx) => String(options.mapping(idx)));

  if (totalPage > maxPageLength) {
    pageNumbers.unshift("<<");
    pageNumbers.push(">>");
  }

  return { items: pageNumbers.map(text => ({ text })) };
}

function getHeaderCompWithCustomKey(oTable, key) {
  const headerContents = oTable.getHeaderToolbar().getContent();
  return headerContents.find(comp =>
    comp.getCustomData().find(item => item.getKey() === key)
  );
}

function getCustomObject(oObj, key) {
  if (!oObj) {
    return null;
  }
  return oObj.getCustomData().find(item => item.getKey() === key);
}

function getCustomObjectFromHeader(oTable, key) {
  const oSearch = getHeaderCompWithCustomKey(oTable, key);
  if (!oSearch) {
    return null;
  }
  return getCustomObject(oSearch, key);
}

function getCustomValueFromHeader(oTable, key = "search") {
  const oCustom = getCustomObjectFromHeader(oTable, key);
  if (!oCustom) {
    return "";
  }
  return oCustom.getValue();
}

function getTargetColumns(tableData) {
  const searchData = tableData.toolbar.content.find(
    ({ component }) => component.type === "SearchField"
  );
  if (searchData.targetColumns) {
    return searchData.targetColumns;
  }
  return tableData.columns.map(({ name }) => name);
}

function getPageNumberContainer(oTable) {
  return oTable
    .getParent()
    .getItems()
    .find(item => item.getMetadata().getName() === "sap.m.Toolbar")
    .getContent()
    .find(item => item.getMetadata().getName() !== "sap.m.ToolbarSpacer");
}

const compareSearchInput = searchInput => value => {
  if (value === "" || value === null || value === undefined) return false;
  return value
    .toString()
    .toLowerCase()
    .includes(searchInput.toLowerCase());
};

function getItemLengthAfterSearch(oTable, tableData) {
  const searchHandler = tryit(() =>
    tableData.toolbar.content.find(
      ({ component }) => component.type === "SearchField"
    )
  );
  if (!searchHandler || searchHandler.component.properties.search) {
    return tableData.items.list.length;
  }
  const targetColumns = getTargetColumns(tableData);
  const searchInput = getCustomValueFromHeader(oTable);
  return tableData.items.list.filter(
    item =>
      targetColumns
        .map(name => item[name])
        .filter(compareSearchInput(searchInput)).length
  ).length;
}

function getNewPage(pressedNumber, totalPage) {
  switch (pressedNumber) {
    case "<<":
      return 1;
    case ">>":
      return totalPage;
    default:
      return Number(pressedNumber) > totalPage ? 1 : Number(pressedNumber);
  }
}

function getParamsForPagination(oTable, tableData, newPage) {
  const { initial, itemsPerPage, edge } = tableData.usePagination;
  const filteredLength = getItemLengthAfterSearch(oTable, tableData);
  const totalPage = Math.ceil(filteredLength / itemsPerPage);
  const pageNum = getNewPage(newPage, totalPage);
  return { initial, edge, itemsPerPage, filteredLength, totalPage, pageNum };
}

function emphasizePageButton(buttonContainer, pageNum) {
  buttonContainer.getItems().forEach(button => {
    if (Number(button.getText()) === pageNum) {
      button.setType(window.sap.m.ButtonType.Emphasized);
      return;
    }
    button.setType(window.sap.m.ButtonType.Transparent);
  });
}

/**
 * [57개 중 1-5]와 같이 표시되는 페이지 정보를 변경합니다.
 */
function changePageIndexInfo(oTable, options = {}) {
  const { filteredLength = 0, itemsPerPage = 0, pageNum = 0 } = options;
  const oText = getHeaderCompWithCustomKey(oTable, "index");
  const total = filteredLength;
  const from = total === 0 ? 0 : itemsPerPage * pageNum - itemsPerPage + 1;
  const to = total < itemsPerPage * pageNum ? total : itemsPerPage * pageNum;
  oText.setText(`${total}개 중 ${from}-${to}`);
}

/**
 * 필터를 적용하여 원하는 수의 항목을 페이지 별로 표시합니다.
 */
function applyFilterOnTable(oTable, tableData, { itemsPerPage, pageNum }) {
  const start = itemsPerPage * (pageNum - 1);
  const end = itemsPerPage * pageNum;
  const oCustom = getCustomObject(oTable, "pageNum");
  if (oCustom) {
    oCustom.setValue(pageNum);
  }

  let count = -1;
  const oIndexFilter = new window.sap.ui.model.Filter({
    path: "",
    test: () => {
      count += 1;
      return count >= start && count < end;
    }
  });

  const searchHandler = tryit(() =>
    tableData.toolbar.content.find(
      ({ component }) => component.type === "SearchField"
    )
  );
  if (!searchHandler || searchHandler.component.properties.search) {
    oTable.getBinding("items").filter(oIndexFilter);
    return;
  }

  const searchInput = getCustomValueFromHeader(oTable);

  const targetColumns = getTargetColumns(tableData);
  const searchFilter = new window.sap.ui.model.Filter({
    filters: targetColumns.map(
      name =>
        new window.sap.ui.model.Filter({
          path: name,
          test: compareSearchInput(searchInput)
        })
    ),
    and: false
  });

  oTable.getBinding("items").filter([searchFilter, oIndexFilter]);
}

/**
 * 설정된 옵션에 따라 pagination의 형태를 변경합니다.
 * @param {*} setData 최초 렌더 시 컴포넌트가 생성되지 않은 상태이므로
 *                    컴포넌트 생성 시 callback의 setData를 가져옵니다.
 */
function changePageNumbers(oComp, options = {}, setData) {
  const { totalPage, edge, pageNum } = options;
  const oContainer = ["sap.m.Table", "sap.m.List"].includes(
    oComp.getMetadata().getName()
  )
    ? getPageNumberContainer(oComp)
    : oComp;
  const buttonSchemes = getButtonSchemes(pageNum, totalPage, edge);

  const oToolbar = oContainer.getParent();
  if (buttonSchemes.items.length === 0) {
    oToolbar && oToolbar.setVisible(false);
  } else {
    oToolbar && oToolbar.setVisible(true);
  }
  if (setData) {
    setData(buttonSchemes);
  } else {
    oContainer.getModel().setData(buttonSchemes);
  }

  emphasizePageButton(oContainer, pageNum);
}

export {
  getHeaderCompWithCustomKey,
  applyFilterOnTable,
  compareSearchInput,
  getCustomObject
};

function handlePagination(oTable, tableData, newPage = "1") {
  const params = { ...getParamsForPagination(oTable, tableData, newPage) };
  changePageIndexInfo(oTable, params);
  const oToolbar = oTable.getHeaderToolbar();
  if (oToolbar.getVisible() === false) {
    oToolbar.setVisible(true);
  }
  return {
    onInitial(oPageNumberContainer, setData) {
      applyFilterOnTable(oTable, tableData, params);
      changePageNumbers(oPageNumberContainer, params, setData);
    },
    onPress() {
      applyFilterOnTable(oTable, tableData, params);
      changePageNumbers(oTable, params);
    },
    onSearch() {
      changePageNumbers(oTable, params);
    }
  };
}

export default handlePagination;
