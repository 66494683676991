import React from "react";
import * as all from "@bsgp/form-and-table";
import { IConstructorProps } from "@/types/common";
import NodeEditorIterator from "./Iterator";
import BaseConstructor from "../common/BaseConstructor";

const { constructNodeEditorData } = all;

const NodeEditorConstructor = (props: IConstructorProps) => (
  <BaseConstructor
    {...props}
    dataKey="nodeeditor"
    dataProcessor={data => {
      return constructNodeEditorData(data.nodeeditor, {
        isReact: true
      });
    }}
  >
    {renderProps => <NodeEditorIterator {...renderProps} />}
  </BaseConstructor>
);

export default NodeEditorConstructor;
