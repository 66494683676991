import React, { createContext, useCallback, useState } from "react";
import {
  INodeEditorBuilderProps,
  INodeJson,
  IRawNodeValue
} from "@/types/nodeEditor";
import { isTruthy } from "@bsgp/lib-core";
import { CustomEdge, CustomNode } from "@/types/nodeEditor";
import { Position } from "reactflow";

const initialState = {
  nodeInfo: [],
  edgeInfo: [],
  rawInfo: null,
  initializeNodeEditor: () => {}
};

interface INodeEditorContext {
  nodeInfo: CustomNode;
  edgeInfo: CustomEdge;
  rawInfo: INodeJson;
  initializeNodeEditor: (props: INodeEditorBuilderProps) => void;
}

export const NodeEditorContext = createContext<INodeEditorContext>(
  initialState
);

export const NodeEditorContextProvider = ({ children }) => {
  const [nodeInfo, setNodeInfo] = useState<CustomNode>([]);
  const [edgeInfo, setEdgeInfo] = useState<CustomEdge>([]);
  const [rawInfo, setRawInfo] = useState<INodeJson>(null);

  const initializeNodeEditor = useCallback(
    (props: INodeEditorBuilderProps) => {
      const { value } = props;

      if (!value || !isTruthy(value)) return;
      const { node, edge } = getNodeEditorInfo(value);
      setRawInfo(value);
      setNodeInfo(node);
      setEdgeInfo(edge);
    },
    [setNodeInfo, setEdgeInfo, setRawInfo]
  );

  return (
    <NodeEditorContext.Provider
      value={{
        nodeInfo,
        edgeInfo,
        rawInfo,
        initializeNodeEditor
      }}
    >
      {children}
    </NodeEditorContext.Provider>
  );
};

const getNodeEditorInfo = (
  json: INodeJson
): { node: CustomNode; edge: CustomEdge } => {
  const edge = [];
  const node = Object.entries(json)
    .map(([key, value], index) => {
      const reservedKeys = ["begin", "end", "id"];
      if (reservedKeys.includes(key)) return null;
      const { next, position, name, id } = value as IRawNodeValue;
      const [xVal, yVal] = position;
      if (next) {
        edge.push({ id: `${key}-${next}`, source: key, target: next });
      }
      return {
        type:
          name === "Input" ? "input" : name === "Output" ? "output" : "custom",
        id: key,
        position: { x: Math.floor(xVal), y: Math.floor(yVal) },
        data: { label: key, id, name },
        sourcePosition: Position.Right,
        targetPosition: Position.Left
      };
    })
    .filter(Boolean);

  return {
    node,
    edge
  };
};
