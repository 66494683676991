import React from "react";
import { Button, ButtonDesign } from "@ui5/webcomponents-react";
import { IBarcodeButtonProps, IButtonProps } from "@/types/component";
import BarcodeScannerButton from "./BarcodeScannerButton";
import { ButtonTypeToDesignMap } from "@/maps/legacyMap";

const FtButton = function FtButton(props: IButtonProps | IBarcodeButtonProps) {
  const { properties = {}, style, isScannerButton = false, ...rest } = props;
  const { text, type, ...restProperties } = properties;
  const onClick = properties.onClick || props.onClick;
  const buttonText = text === undefined ? props.value || props.children : text;

  return isScannerButton ? (
    <BarcodeScannerButton {...props} />
  ) : (
    <Button
      {...rest}
      design={ButtonTypeToDesignMap[type] || ButtonDesign.Default}
      {...restProperties}
      onClick={onClick}
      style={style}
    >
      {buttonText}
    </Button>
  );
};

export default FtButton;
