import React, { memo } from "react";
import { AnalyticalTable } from "@ui5/webcomponents-react";

const FtAnalyticalTable = props => {
  return <AnalyticalTable {...props} />;
};

FtAnalyticalTable.displayName = "AnalyticalTable in ft-react";

export default memo(FtAnalyticalTable);
