import React from "react";
import { MessageStrip, MessageStripDesign } from "@ui5/webcomponents-react";
import { messageStripDesignMap } from "@/maps/legacyMap";

interface IMessageStripProps {
  id?: string;
  properties?: {
    type?: string;
  };
  type?: string;
  value?: string;
}

const FtMessageStrip = (props: IMessageStripProps) => {
  const { value, properties = {} } = props;

  return (
    <MessageStrip
      design={
        messageStripDesignMap[properties.type] ||
        properties.type ||
        MessageStripDesign.Information
      }
      {...props}
    >
      {value}
    </MessageStrip>
  );
};

export default FtMessageStrip;
