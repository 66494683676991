import React from "react";
import { ObjectStatus } from "@ui5/webcomponents-react";

const FtObjectStatus = props => {
  const { properties = {}, text, style, value } = props;
  const { emptyIndicatorMode = "", visible, ...restProperties } = properties;
  const emptyIndicator = emptyIndicatorMode === "On" ? true : false;
  return (
    <ObjectStatus
      {...restProperties}
      emptyIndicator={emptyIndicator}
      style={style}
    >
      {text || value}
    </ObjectStatus>
  );
};

export default FtObjectStatus;
