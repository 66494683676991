import React, { memo } from "react";
import { Table, TableRow } from "@/components";
import { useTableContext } from "@/contexts/tableContext";
import TableCellBuilder from "./TableCellBuilder";
import ColumnBuilder from "./ColumnBuilder";
import { refSetterSelectedRows } from "@/contexts/dialogContext";
import { TableMode } from "@ui5/webcomponents-react";

const TableComponentBuilder = ({ header, footer }) => {
  const { dataDisplaying, tableProperties } = useTableContext();

  const { name, columnProperties, onSelect, ...rest } = tableProperties;
  const { columns } = columnProperties;
  return (
    <>
      {header}
      <Table
        stickyColumnHeader={true}
        {...rest}
        onSelectionChange={event => {
          if (!rest.mode || rest.mode === TableMode.None) return;
          const selectedNodes = event.detail.selectedRows;

          // 셀렉트된 idx 필요하면 아래와 같이 쓰기
          const selectedIndices = selectedNodes.map(row =>
            Number(row.dataset.idx)
          );

          // 셀렉트된 row data
          const selectedRows = selectedNodes.map(row =>
            JSON.parse(row.dataset.rowdata)
          );

          // TODO: 실제로 dialog 안에 있는 테이블인 경우에만 selectedRows state를 밖으로 뺄지 결정
          // if (refSetterSelectedRows.current) {
          // valueHelpV2 dialog 안에 있는 테이블인 경우. selectedRows state를 밖으로 뺌
          refSetterSelectedRows.current(selectedRows);
          // }

          onSelect && onSelect({ ...event, selectedRows, selectedIndices });
        }}
        columns={columns.map((col, idx) => (
          <ColumnBuilder
            data={col}
            tableName={name}
            key={`${name}_columns_${idx}`}
          />
        ))}
      >
        {dataDisplaying.map((row, idx) => (
          <TableRow
            key={`TableRow-${idx}`}
            data-idx={idx}
            data-rowData={JSON.stringify(row)}
          >
            <TableCellBuilder
              {...tableProperties}
              rowData={{ ...dataDisplaying[idx], index: idx }}
            />
          </TableRow>
        ))}
      </Table>
      {footer}
    </>
  );
};

export default memo(TableComponentBuilder);
