export const toolbarTypeMap = {
  Button: "Button",
  Select: "Select",
  SelectOption: "SelectOption",
  Separator: "ToolbarSeparator",
  Spacer: "ToolbarSpacer",
  ViewSettings: "ViewSettings"
};

export const toolbarV2TypeMap = {
  Button: "ToolbarButton",
  ToolbarButton: "ToolbarButton",
  Select: "ToolbarSelect",
  ToolbarSelect: "ToolbarSelect",
  SelectOption: "ToolbarSelectOption",
  ToolbarSelectOption: "ToolbarSelectOption",
  Separator: "ToolbarSeparatorV2",
  ToolbarSeparator: "ToolbarSeparatorV2",
  Spacer: "ToolbarSpacerV2",
  ToolbarSpacer: "ToolbarSpacerV2",
  ViewSettings: "ViewSettings"
};
