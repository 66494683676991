import React from "react";
import { Handle, NodeProps, Position } from "reactflow";

interface ICustomNode extends NodeProps {
  onNodeDoubleClick: (event: React.MouseEvent) => void;
}

function CustomNode(props: ICustomNode) {
  const { isConnectable, id } = props;

  return (
    <div className="react-flow__node-default">
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      {id}
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  );
}

export default CustomNode;
