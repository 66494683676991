import React from "react";
import { Toolbar } from "@/components";
import { isTruthy } from "@bsgp/lib-core";
import { ToolbarDesign } from "@ui5/webcomponents-react";
import ToolbarComponentBuilder from "@/builders/common/ToolbarComponentBuilder";
import { ITableData } from "@/types/table";
import DataCntInfo from "@/components/tables/DataCntInfo";

interface IToolbarBuilderProps {
  tableName: string;
  tableInfo: ITableData;
}

const ToolbarBuilder = (props: IToolbarBuilderProps) => {
  const { tableName, tableInfo } = props;
  const { toolbar, usePagination } = tableInfo;

  if (!toolbar) return null;

  return (
    <Toolbar
      name={`${tableName}_header_toolbar`}
      style={{
        ...(!isTruthy(toolbar) ? { display: "none" } : {})
      }}
      design={ToolbarDesign.Transparent}
      className={"sapUiSizeCompact"}
      {...(isTruthy(toolbar) && {
        ...toolbar.properties
      })}
    >
      {toolbar.content
        .map(bar => {
          return (
            <ToolbarComponentBuilder
              key={`${tableName}_header_toolbar_content_${bar.name}`}
              tableInfo={tableInfo}
              {...bar}
              {...bar.component}
              refineEventHookName={"useRefineEventForTableToolbar"}
            />
          );
        })
        .filter(Boolean)}
      {usePagination && <DataCntInfo />}
    </Toolbar>
  );
};

export default ToolbarBuilder;
