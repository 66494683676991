import React from "react";
import * as all from "@bsgp/form-and-table";
import { IConstructorProps } from "@/types/common";
import { propsRef } from "@/ref";
import BaseConstructor from "../common/BaseConstructor";
import FormIterator from "./FormIterator";

const { constructFormData } = all;

const FormConstructor = (props: IConstructorProps) => {
  const { data, ...options } = props;
  return (
    <BaseConstructor
      {...props}
      dataKey="form"
      dataProcessor={data => constructFormData(data.form, propsRef.current.fn)}
      additionalCheck={() => !!propsRef.current.fn}
      additionalProps={{ wrapForms: props.data.wrapForms, ...options }}
    >
      {renderProps => <FormIterator {...renderProps} />}
    </BaseConstructor>
  );
};

export default FormConstructor;
