import { IValueHelpV2Event } from "@/types/dialog";
import React, { createContext, useState, createRef, useContext } from "react";

interface IDialogContext {
  openDialogIds: string[];
  valueHelpV2Event: IValueHelpV2Event;
  selectedRows: any[];
}

export const DialogContext = createContext<IDialogContext>({
  openDialogIds: [],
  valueHelpV2Event: {},
  selectedRows: []
});

type RefSetterType<T> = React.MutableRefObject<
  React.Dispatch<React.SetStateAction<T>>
>;

export const refSetterOpenDialogIds: RefSetterType<string[]> = createRef();
type ValueHelpSetterType = RefSetterType<IValueHelpV2Event>;
export const refSetterValueHelpV2Event: ValueHelpSetterType = createRef();
export const refSetterSelectedRows: RefSetterType<any[]> = createRef();

export const DialogContextProvider = ({ children }) => {
  const [openDialogIds, setOpenDialogIds] = useState<string[]>([]);
  const [valueHelpV2Event, setValueHelpV2Event] = useState<IValueHelpV2Event>(
    {}
  );
  const [selectedRows, setSelectedRows] = useState([]);

  refSetterOpenDialogIds.current = setOpenDialogIds;
  refSetterValueHelpV2Event.current = setValueHelpV2Event;
  refSetterSelectedRows.current = setSelectedRows;

  return (
    <DialogContext.Provider
      value={{
        openDialogIds,
        valueHelpV2Event,
        selectedRows
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => useContext(DialogContext);
