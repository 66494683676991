import React from "react";
import { IColumnData, ITableData } from "@/types/table";
import { Text, VBox } from "@/components";
import DynamicTableBuilder from "./DynamicTableBuilder";
import Pagination from "@/components/tables/Pagination";
import ToolbarBuilder from "./ToolbarBuilder";
import { TableContextProvider } from "@/contexts/tableContext";

const TableBuilder = (props: ITableData) => {
  const { columns, name: tableName, items } = props;
  const showFooter = columns.some((col: IColumnData) => col.showSum === true);

  return (
    <VBox name={`vertical-box-${tableName}`}>
      <TableContextProvider>
        <DynamicTableBuilder
          data={props}
          tableName={tableName}
          items={items}
          header={<ToolbarBuilder tableName={tableName} tableInfo={props} />}
          footer={
            showFooter && columns.map(col => <Text value={col.footerText} />)
          }
        />
        <Pagination usePagination={props.usePagination} />
      </TableContextProvider>
    </VBox>
  );
};

export default TableBuilder;
