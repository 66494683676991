import React from "react";
import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxPropTypes,
  PageBackgroundDesign
} from "@ui5/webcomponents-react";

interface IVBoxProps extends FlexBoxPropTypes {
  backgroundDesign?: PageBackgroundDesign;
  name?: string;
}
function VBox(props: IVBoxProps) {
  const { style, children, ...restProps } = props;
  return (
    <FlexBox
      direction={FlexBoxDirection.Column}
      alignItems={props.alignItems || FlexBoxAlignItems.Stretch}
      style={{ minWidth: "16rem", ...style }}
      {...restProps}
    >
      {children}
    </FlexBox>
  );
}

VBox.displayName = "VBox";
export default VBox;
